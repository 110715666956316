<template>
	<div class="my-5">
		<v-container>
			<v-row align="center" justify="center">
				<v-col cols="12" md="6">
					<div class="secondary--text">Hi!</div>
					<div class="text-h4 font-weight-medium primary--text">Securely login to your <br>account<span class="secondary--text">.</span></div>
					<div class="my-6 d-none d-md-block d-lg-block">
						<img src="@/assets/icons/auth.png" alt="Authentication" width="330px">
					</div>
					<div class="text-secondary text-caption d-none d-md-block d-lg-block">Trouble Signing in? <br /><router-link to="/register"> Contact Us: +234 803 987 3400 or +234 806 170 5599</router-link> <br /> 
					Email:  <span class="primary--text"><a href="maito:info@transave.com.ng">info@transave.com.ng</a></span></div>
				</v-col>
				<v-col cols="12" md="5">
					<v-card class="py-10 px-10 auth-box lighten-5 customized-form rounded-xl" outlined>
						<div class="text-center">
							<div class="text-subtitle-1 font-weight-medium">Verify Transave URL <br /> <strong class="secondary--text"><v-icon class="success--text mr-1" style="vertical-align: top">mdi-lock</v-icon>https://app.transave.com.ng</strong></div>
							<div class="mt-2 red--text small" v-if="error">{{error_message}}</div>
						</div>
						<v-form ref="login" class="mt-8" v-model="valid" lazy-validation>
							<p class="label">Phone Number</p>
							<v-text-field
								v-model="form.phone"
								@keypress.native.enter="$refs.login.validate() ? loginAccount() : null"
								
								required autofocus 
								:rules="rules"
								outlined
							>
							<!--@keydown="validNumber($event)"-->
							</v-text-field>
							<p class="label">Password</p>
							<v-text-field
								v-model="form.password"
								@keydown="clearErrors"
								@keypress.native.enter="$refs.login.validate() ? loginAccount() : null"
								type="password"
								required
								:rules="passwordRules"
								outlined
							>
							</v-text-field>
							<v-btn
								block
								x-large
								class="primary auth-btn"
								:loading="loading"
								@click="$refs.login.validate() ? loginAccount() : null"
								>Login</v-btn
							>
						</v-form>
					</v-card>
					
						<div class="text-center mt-4">
							<small class="">Don't have an account? <router-link to="/register"> Register here</router-link></small> | 
							<small><router-link to="/forgot-password">Forgot Password?</router-link></small><br>
						</div>
				</v-col>
			</v-row>
		</v-container>
	</div>

</template>
<script>
import { mapActions } from 'vuex'
export default {
	data() {
		return {
			valid: true,
			loading: false,
			form: { password: '' },
			rules: [
				(v) => !!v || 'Please enter your phone number',
			//	(v) => /^[0-9]*$/.test(v) || "Please enter a valid number"
			],
			passwordRules: [
				(v) => !!v || 'Password is required',
				(v) => v.length >= 6 || 'Password must be at least six characters',
			],
			error: false,
			error_message: '',
		}
	},
	methods: {
		...mapActions('auth', [
			'login'
		]),

		async loginAccount() {
			try {
				this.clearErrors();
				this.loading = true;
				this.form.platform = 'web';
				let response = await this.login(this.form);
				this.loading = false;
				this.$toast.success('Login successful');
				setTimeout(() => {
					this.$router.push('/dashboard')
				}, 3000)
			}
			catch (err) {
				this.$toast.error(err.response.data.message)
				this.loading = false
			}
		},
		clearErrors() {
			this.error = false;
			this.error_message = '';
		},
		postCross() {
			var msg = {"login": "true"};
			var win = document.getElementById('ifr').contentWindow;
			win.postMessage(msg, "https://transave.com.ng");
		},
		sendData(){
			var postMsg = {"login": "true"};
			this.postCross(postMsg);
		},

		validNumber(e) {
			e.target.value = e.target.value.replace(/[^0-9]+/g, '');
		},
	},
}
</script>

<style lang="scss" scoped>
    .auth-box {
	//box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
	h4 {
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-size: 30px !important;
		line-height: 52px !important;
		color: #3a3838 !important;
	}
	small {
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-size: 14px !important;
		line-height: 27px !important;
		color: #3a3838 !important;
	}
	p.label {
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: normal !important;
		font-size: 17px !important;
		line-height: 15px !important;
		color: #171b70 !important;
	}
	}
	.auth-btn {
		background: #171b70 !important;
		border: 0.5px solid #171b70 !important;
		box-shadow: 0px 1px 4px 1px rgba(66, 70, 149, 0.45) !important;
		border-radius: 5px !important;
		font-family: Poppins !important;
		font-style: normal !important;
		font-weight: 600 !important;
		font-size: 20px !important;
		line-height: 37px !important;
		text-transform: capitalize !important;
	}
	a {
		text-decoration: none !important;
	}
</style>